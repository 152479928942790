.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.dashboard-item {
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  transition: all 200ms ease;
  transition-property: left, top;
  background: #fff;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  /* text-align: center; */
  border-radius: 12px;
  border: 1px solid #787878;
  /* z-index: -9 !important; */
}
.dashboard-item:hover {
  z-index: 10 !important ;
}

.react-grid-item.react-grid-placeholder {
  background-color: rgb(49, 43, 102);
  border-radius: 12px;
}

.deleteButton {
  position: absolute;
  top: 0%;
  left: 92%;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

@media print {
  .dashboard-grid-container {
    margin: 0;
    padding: 0;
  }
  .dashboard-item {
    width: 100%;
  }
}

.react-resizable-handle {
  z-index: 1500;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 10px;
  height: 10px;
  border-right: 3px solid rgba(80, 77, 77, 0.4);
  border-bottom: 3px solid rgba(80, 77, 77, 0.4);
}

.react-resizable-handle {
  visibility: hidden;
}

.react-grid-item:hover .react-resizable-handle {
  visibility: visible;
}
